import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6a9a14a3 = () => interopDefault(import('../pages/a-guide-to-a-breakdown-cover/index.vue' /* webpackChunkName: "pages/a-guide-to-a-breakdown-cover/index" */))
const _b8589bec = () => interopDefault(import('../pages/about/index.vue' /* webpackChunkName: "pages/about/index" */))
const _5d8d79b5 = () => interopDefault(import('../pages/air-filters/index.vue' /* webpackChunkName: "pages/air-filters/index" */))
const _252f4ba3 = () => interopDefault(import('../pages/alloy-wheel-insurance/index.vue' /* webpackChunkName: "pages/alloy-wheel-insurance/index" */))
const _5431a4c2 = () => interopDefault(import('../pages/alternators/index.vue' /* webpackChunkName: "pages/alternators/index" */))
const _727549d6 = () => interopDefault(import('../pages/BMGSMS/index.vue' /* webpackChunkName: "pages/BMGSMS/index" */))
const _73547682 = () => interopDefault(import('../pages/body-and-exhaust/index.vue' /* webpackChunkName: "pages/body-and-exhaust/index" */))
const _a2412668 = () => interopDefault(import('../pages/book-a-mot-in-northern-ireland/index.vue' /* webpackChunkName: "pages/book-a-mot-in-northern-ireland/index" */))
const _44c977f9 = () => interopDefault(import('../pages/book-driving-test/index.vue' /* webpackChunkName: "pages/book-driving-test/index" */))
const _cf65de32 = () => interopDefault(import('../pages/book-mot/index.vue' /* webpackChunkName: "pages/book-mot/index" */))
const _c3b87724 = () => interopDefault(import('../pages/Book-MOT-Test/index.vue' /* webpackChunkName: "pages/Book-MOT-Test/index" */))
const _556b4e4f = () => interopDefault(import('../pages/book-theory-test/index.vue' /* webpackChunkName: "pages/book-theory-test/index" */))
const _1e9a4974 = () => interopDefault(import('../pages/BookMOTNow/index.vue' /* webpackChunkName: "pages/BookMOTNow/index" */))
const _122766de = () => interopDefault(import('../pages/buy-a-car/index.vue' /* webpackChunkName: "pages/buy-a-car/index" */))
const _1a0dbdab = () => interopDefault(import('../pages/buying-and-replacing-spark-plugs/index.vue' /* webpackChunkName: "pages/buying-and-replacing-spark-plugs/index" */))
const _1f97ad79 = () => interopDefault(import('../pages/car-accident-claims/index.vue' /* webpackChunkName: "pages/car-accident-claims/index" */))
const _f8998a22 = () => interopDefault(import('../pages/car-batteries/index.vue' /* webpackChunkName: "pages/car-batteries/index" */))
const _7d8a9890 = () => interopDefault(import('../pages/car-grilles/index.vue' /* webpackChunkName: "pages/car-grilles/index" */))
const _d8d4d8de = () => interopDefault(import('../pages/car-mot-check/index.vue' /* webpackChunkName: "pages/car-mot-check/index" */))
const _d51ed6b8 = () => interopDefault(import('../pages/car-parts/index.vue' /* webpackChunkName: "pages/car-parts/index" */))
const _d1597aee = () => interopDefault(import('../pages/car-service-near-me/index.vue' /* webpackChunkName: "pages/car-service-near-me/index" */))
const _e6a3ae36 = () => interopDefault(import('../pages/car-servicing-guide/index.vue' /* webpackChunkName: "pages/car-servicing-guide/index" */))
const _6a36c5ce = () => interopDefault(import('../pages/car-warranty/index.vue' /* webpackChunkName: "pages/car-warranty/index" */))
const _3cf58043 = () => interopDefault(import('../pages/catalytic-converters/index.vue' /* webpackChunkName: "pages/catalytic-converters/index" */))
const _337c74b4 = () => interopDefault(import('../pages/certificate-of-entitlement/index.vue' /* webpackChunkName: "pages/certificate-of-entitlement/index" */))
const _6e8963aa = () => interopDefault(import('../pages/check-car-batteries/index.vue' /* webpackChunkName: "pages/check-car-batteries/index" */))
const _2511ad7d = () => interopDefault(import('../pages/choose-plan-sell-car.vue' /* webpackChunkName: "pages/choose-plan-sell-car" */))
const _21baa356 = () => interopDefault(import('../pages/claim-for-car-accident/index.vue' /* webpackChunkName: "pages/claim-for-car-accident/index" */))
const _1a0cbb66 = () => interopDefault(import('../pages/commercial-van-insurance/index.vue' /* webpackChunkName: "pages/commercial-van-insurance/index" */))
const _513bf334 = () => interopDefault(import('../pages/complaints-handeling-procedure/index.vue' /* webpackChunkName: "pages/complaints-handeling-procedure/index" */))
const _1110c892 = () => interopDefault(import('../pages/congestion-charge-check/index.vue' /* webpackChunkName: "pages/congestion-charge-check/index" */))
const _366f1ac6 = () => interopDefault(import('../pages/congestion-charge-claim/index.vue' /* webpackChunkName: "pages/congestion-charge-claim/index" */))
const _782c1346 = () => interopDefault(import('../pages/contact/index.vue' /* webpackChunkName: "pages/contact/index" */))
const _1b90160c = () => interopDefault(import('../pages/cookies/index.vue' /* webpackChunkName: "pages/cookies/index" */))
const _95baaf78 = () => interopDefault(import('../pages/cosmetic-repair-insurance-guide/index.vue' /* webpackChunkName: "pages/cosmetic-repair-insurance-guide/index" */))
const _6e343c24 = () => interopDefault(import('../pages/dataprotectionpolicy/index.vue' /* webpackChunkName: "pages/dataprotectionpolicy/index" */))
const _17115ad9 = () => interopDefault(import('../pages/DVLA-contact-number/index.vue' /* webpackChunkName: "pages/DVLA-contact-number/index" */))
const _11a74802 = () => interopDefault(import('../pages/dvla-licence-check/index.vue' /* webpackChunkName: "pages/dvla-licence-check/index" */))
const _30235bdb = () => interopDefault(import('../pages/dvla-retention-certificate/index.vue' /* webpackChunkName: "pages/dvla-retention-certificate/index" */))
const _49cab364 = () => interopDefault(import('../pages/electrical-and-lighting/index.vue' /* webpackChunkName: "pages/electrical-and-lighting/index" */))
const _d1e97080 = () => interopDefault(import('../pages/engine-oil/index.vue' /* webpackChunkName: "pages/engine-oil/index" */))
const _13ac460c = () => interopDefault(import('../pages/exhaust-parts/index.vue' /* webpackChunkName: "pages/exhaust-parts/index" */))
const _5f6d3f1d = () => interopDefault(import('../pages/fuel-filters/index.vue' /* webpackChunkName: "pages/fuel-filters/index" */))
const _700a33e2 = () => interopDefault(import('../pages/gap-insurance-guide/index.vue' /* webpackChunkName: "pages/gap-insurance-guide/index" */))
const _f507107a = () => interopDefault(import('../pages/historical-MOT-Check/index.vue' /* webpackChunkName: "pages/historical-MOT-Check/index" */))
const _d7b79b36 = () => interopDefault(import('../pages/how-to-cancel-a-mot/index.vue' /* webpackChunkName: "pages/how-to-cancel-a-mot/index" */))
const _4d7ea36c = () => interopDefault(import('../pages/InformationSecurityBreachReporting/index.vue' /* webpackChunkName: "pages/InformationSecurityBreachReporting/index" */))
const _894c53c8 = () => interopDefault(import('../pages/local-mot-guide/index.vue' /* webpackChunkName: "pages/local-mot-guide/index" */))
const _67288d4a = () => interopDefault(import('../pages/lubricants-and-fluids/index.vue' /* webpackChunkName: "pages/lubricants-and-fluids/index" */))
const _648d3429 = () => interopDefault(import('../pages/mot-center/index.vue' /* webpackChunkName: "pages/mot-center/index" */))
const _3ea76883 = () => interopDefault(import('../pages/mot-center-cities/index.vue' /* webpackChunkName: "pages/mot-center-cities/index" */))
const _394a8880 = () => interopDefault(import('../pages/mot-centers/index.vue' /* webpackChunkName: "pages/mot-centers/index" */))
const _eba4b6ce = () => interopDefault(import('../pages/mot-certificate/index.vue' /* webpackChunkName: "pages/mot-certificate/index" */))
const _6c80086c = () => interopDefault(import('../pages/MOT-Check-News/index.vue' /* webpackChunkName: "pages/MOT-Check-News/index" */))
const _638f01b1 = () => interopDefault(import('../pages/MOT-history-check/index.vue' /* webpackChunkName: "pages/MOT-history-check/index" */))
const _35d8a742 = () => interopDefault(import('../pages/mot-near-me/index.vue' /* webpackChunkName: "pages/mot-near-me/index" */))
const _0e4e0561 = () => interopDefault(import('../pages/mot-test-check/index.vue' /* webpackChunkName: "pages/mot-test-check/index" */))
const _ee16296c = () => interopDefault(import('../pages/motorcycle-battery/index.vue' /* webpackChunkName: "pages/motorcycle-battery/index" */))
const _6189174a = () => interopDefault(import('../pages/motorcycle-mot-check/index.vue' /* webpackChunkName: "pages/motorcycle-mot-check/index" */))
const _2a98fac7 = () => interopDefault(import('../pages/my-diesel-claim/index.vue' /* webpackChunkName: "pages/my-diesel-claim/index" */))
const _177e442b = () => interopDefault(import('../pages/number-plates-in-the-uk/index.vue' /* webpackChunkName: "pages/number-plates-in-the-uk/index" */))
const _3bec9c3d = () => interopDefault(import('../pages/oil-filters/index.vue' /* webpackChunkName: "pages/oil-filters/index" */))
const _5b10c35a = () => interopDefault(import('../pages/pre-purchase-inspection/index.vue' /* webpackChunkName: "pages/pre-purchase-inspection/index" */))
const _6b5b391d = () => interopDefault(import('../pages/prime-membership/index.vue' /* webpackChunkName: "pages/prime-membership/index" */))
const _30c1e3f9 = () => interopDefault(import('../pages/print-mot-certificate/index.vue' /* webpackChunkName: "pages/print-mot-certificate/index" */))
const _6c29d3c5 = () => interopDefault(import('../pages/privacy/index.vue' /* webpackChunkName: "pages/privacy/index" */))
const _765432c4 = () => interopDefault(import('../pages/rate-mot/index.vue' /* webpackChunkName: "pages/rate-mot/index" */))
const _36b16d24 = () => interopDefault(import('../pages/refund-policy/index.vue' /* webpackChunkName: "pages/refund-policy/index" */))
const _24698f78 = () => interopDefault(import('../pages/register-a-MOT-test-center/index.vue' /* webpackChunkName: "pages/register-a-MOT-test-center/index" */))
const _37ea1c28 = () => interopDefault(import('../pages/scrap-my-car/index.vue' /* webpackChunkName: "pages/scrap-my-car/index" */))
const _4de187d2 = () => interopDefault(import('../pages/sell-car/index.vue' /* webpackChunkName: "pages/sell-car/index" */))
const _91e0f26a = () => interopDefault(import('../pages/sell-my-car/index.vue' /* webpackChunkName: "pages/sell-my-car/index" */))
const _5cc4625e = () => interopDefault(import('../pages/sorn/index.vue' /* webpackChunkName: "pages/sorn/index" */))
const _1e83f545 = () => interopDefault(import('../pages/sorn-my-car/index.vue' /* webpackChunkName: "pages/sorn-my-car/index" */))
const _4bc0a855 = () => interopDefault(import('../pages/starter-motors/index.vue' /* webpackChunkName: "pages/starter-motors/index" */))
const _59fe15a7 = () => interopDefault(import('../pages/steering-fluid/index.vue' /* webpackChunkName: "pages/steering-fluid/index" */))
const _432e7593 = () => interopDefault(import('../pages/suspension-fluid/index.vue' /* webpackChunkName: "pages/suspension-fluid/index" */))
const _2465b0f8 = () => interopDefault(import('../pages/terms/index.vue' /* webpackChunkName: "pages/terms/index" */))
const _516c6b92 = () => interopDefault(import('../pages/the-ultimate-guide-to-tyres/index.vue' /* webpackChunkName: "pages/the-ultimate-guide-to-tyres/index" */))
const _d988d2a4 = () => interopDefault(import('../pages/trademark/index.vue' /* webpackChunkName: "pages/trademark/index" */))
const _b220453c = () => interopDefault(import('../pages/transmission-oil/index.vue' /* webpackChunkName: "pages/transmission-oil/index" */))
const _69f1cb84 = () => interopDefault(import('../pages/tyre-insurance/index.vue' /* webpackChunkName: "pages/tyre-insurance/index" */))
const _48285d80 = () => interopDefault(import('../pages/ulez-check/index.vue' /* webpackChunkName: "pages/ulez-check/index" */))
const _1c87a7b4 = () => interopDefault(import('../pages/ULEZ-claim/index.vue' /* webpackChunkName: "pages/ULEZ-claim/index" */))
const _3a3fc540 = () => interopDefault(import('../pages/van-mot-check/index.vue' /* webpackChunkName: "pages/van-mot-check/index" */))
const _4a61aa53 = () => interopDefault(import('../pages/VDI-check/index.vue' /* webpackChunkName: "pages/VDI-check/index" */))
const _c1abeed6 = () => interopDefault(import('../pages/vehicle-battery-types/index.vue' /* webpackChunkName: "pages/vehicle-battery-types/index" */))
const _14f87414 = () => interopDefault(import('../pages/vehicle-insurance/index.vue' /* webpackChunkName: "pages/vehicle-insurance/index" */))
const _5f16d583 = () => interopDefault(import('../pages/vehicle-insurance-in-the-uk/index.vue' /* webpackChunkName: "pages/vehicle-insurance-in-the-uk/index" */))
const _32f22909 = () => interopDefault(import('../pages/vulnerable-consumer-policy-and-complaint-handling-procedure/index.vue' /* webpackChunkName: "pages/vulnerable-consumer-policy-and-complaint-handling-procedure/index" */))
const _bfdb35b4 = () => interopDefault(import('../pages/we-buy-any-car/index.vue' /* webpackChunkName: "pages/we-buy-any-car/index" */))
const _3ef432ea = () => interopDefault(import('../pages/whatmot/index.vue' /* webpackChunkName: "pages/whatmot/index" */))
const _69bcad81 = () => interopDefault(import('../pages/when-is-my-mot-due/index.vue' /* webpackChunkName: "pages/when-is-my-mot-due/index" */))
const _96a800ea = () => interopDefault(import('../pages/wing-mirrors/index.vue' /* webpackChunkName: "pages/wing-mirrors/index" */))
const _0d69a848 = () => interopDefault(import('../pages/book-a-mot-in-northern-ireland/confirm-vehicle/index.vue' /* webpackChunkName: "pages/book-a-mot-in-northern-ireland/confirm-vehicle/index" */))
const _d2d320c2 = () => interopDefault(import('../pages/book-mot/_slug.vue' /* webpackChunkName: "pages/book-mot/_slug" */))
const _832bf05c = () => interopDefault(import('../pages/free-mot-check/_reg.vue' /* webpackChunkName: "pages/free-mot-check/_reg" */))
const _5d762938 = () => interopDefault(import('../pages/mot-center/_city/index.vue' /* webpackChunkName: "pages/mot-center/_city/index" */))
const _2451aa42 = () => interopDefault(import('../pages/mot-centers-in/_postcode.vue' /* webpackChunkName: "pages/mot-centers-in/_postcode" */))
const _2c1d3b0a = () => interopDefault(import('../pages/mot-centers/_city/index.vue' /* webpackChunkName: "pages/mot-centers/_city/index" */))
const _6ac96724 = () => interopDefault(import('../pages/MOT-Check-News/_slug.vue' /* webpackChunkName: "pages/MOT-Check-News/_slug" */))
const _22e01178 = () => interopDefault(import('../pages/register-a-MOT-test-center/_token.vue' /* webpackChunkName: "pages/register-a-MOT-test-center/_token" */))
const _b494bfbc = () => interopDefault(import('../pages/mot-center/_city/check-mot/index.vue' /* webpackChunkName: "pages/mot-center/_city/check-mot/index" */))
const _569c5218 = () => interopDefault(import('../pages/mot-center/_city/check-mot/_station/index.vue' /* webpackChunkName: "pages/mot-center/_city/check-mot/_station/index" */))
const _38c7fa80 = () => interopDefault(import('../pages/mot-center/_city/check-mot/_station/_id.vue' /* webpackChunkName: "pages/mot-center/_city/check-mot/_station/_id" */))
const _3b009116 = () => interopDefault(import('../pages/mot-center/_city/_station/index.vue' /* webpackChunkName: "pages/mot-center/_city/_station/index" */))
const _7c4870ba = () => interopDefault(import('../pages/mot-centers/_city/_id.vue' /* webpackChunkName: "pages/mot-centers/_city/_id" */))
const _7e8be0fe = () => interopDefault(import('../pages/mot-center/_city/_station/_id.vue' /* webpackChunkName: "pages/mot-center/_city/_station/_id" */))
const _3d6810e8 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _7344e308 = () => interopDefault(import('../pages/_sellcar/index.vue' /* webpackChunkName: "pages/_sellcar/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/a-guide-to-a-breakdown-cover",
    component: _6a9a14a3,
    name: "a-guide-to-a-breakdown-cover"
  }, {
    path: "/about",
    component: _b8589bec,
    name: "about"
  }, {
    path: "/air-filters",
    component: _5d8d79b5,
    name: "air-filters"
  }, {
    path: "/alloy-wheel-insurance",
    component: _252f4ba3,
    name: "alloy-wheel-insurance"
  }, {
    path: "/alternators",
    component: _5431a4c2,
    name: "alternators"
  }, {
    path: "/BMGSMS",
    component: _727549d6,
    name: "BMGSMS"
  }, {
    path: "/body-and-exhaust",
    component: _73547682,
    name: "body-and-exhaust"
  }, {
    path: "/book-a-mot-in-northern-ireland",
    component: _a2412668,
    name: "book-a-mot-in-northern-ireland"
  }, {
    path: "/book-driving-test",
    component: _44c977f9,
    name: "book-driving-test"
  }, {
    path: "/book-mot",
    component: _cf65de32,
    name: "book-mot"
  }, {
    path: "/Book-MOT-Test",
    component: _c3b87724,
    name: "Book-MOT-Test"
  }, {
    path: "/book-theory-test",
    component: _556b4e4f,
    name: "book-theory-test"
  }, {
    path: "/BookMOTNow",
    component: _1e9a4974,
    name: "BookMOTNow"
  }, {
    path: "/buy-a-car",
    component: _122766de,
    name: "buy-a-car"
  }, {
    path: "/buying-and-replacing-spark-plugs",
    component: _1a0dbdab,
    name: "buying-and-replacing-spark-plugs"
  }, {
    path: "/car-accident-claims",
    component: _1f97ad79,
    name: "car-accident-claims"
  }, {
    path: "/car-batteries",
    component: _f8998a22,
    name: "car-batteries"
  }, {
    path: "/car-grilles",
    component: _7d8a9890,
    name: "car-grilles"
  }, {
    path: "/car-mot-check",
    component: _d8d4d8de,
    name: "car-mot-check"
  }, {
    path: "/car-parts",
    component: _d51ed6b8,
    name: "car-parts"
  }, {
    path: "/car-service-near-me",
    component: _d1597aee,
    name: "car-service-near-me"
  }, {
    path: "/car-servicing-guide",
    component: _e6a3ae36,
    name: "car-servicing-guide"
  }, {
    path: "/car-warranty",
    component: _6a36c5ce,
    name: "car-warranty"
  }, {
    path: "/catalytic-converters",
    component: _3cf58043,
    name: "catalytic-converters"
  }, {
    path: "/certificate-of-entitlement",
    component: _337c74b4,
    name: "certificate-of-entitlement"
  }, {
    path: "/check-car-batteries",
    component: _6e8963aa,
    name: "check-car-batteries"
  }, {
    path: "/choose-plan-sell-car",
    component: _2511ad7d,
    name: "choose-plan-sell-car"
  }, {
    path: "/claim-for-car-accident",
    component: _21baa356,
    name: "claim-for-car-accident"
  }, {
    path: "/commercial-van-insurance",
    component: _1a0cbb66,
    name: "commercial-van-insurance"
  }, {
    path: "/complaints-handeling-procedure",
    component: _513bf334,
    name: "complaints-handeling-procedure"
  }, {
    path: "/congestion-charge-check",
    component: _1110c892,
    name: "congestion-charge-check"
  }, {
    path: "/congestion-charge-claim",
    component: _366f1ac6,
    name: "congestion-charge-claim"
  }, {
    path: "/contact",
    component: _782c1346,
    name: "contact"
  }, {
    path: "/cookies",
    component: _1b90160c,
    name: "cookies"
  }, {
    path: "/cosmetic-repair-insurance-guide",
    component: _95baaf78,
    name: "cosmetic-repair-insurance-guide"
  }, {
    path: "/dataprotectionpolicy",
    component: _6e343c24,
    name: "dataprotectionpolicy"
  }, {
    path: "/DVLA-contact-number",
    component: _17115ad9,
    name: "DVLA-contact-number"
  }, {
    path: "/dvla-licence-check",
    component: _11a74802,
    name: "dvla-licence-check"
  }, {
    path: "/dvla-retention-certificate",
    component: _30235bdb,
    name: "dvla-retention-certificate"
  }, {
    path: "/electrical-and-lighting",
    component: _49cab364,
    name: "electrical-and-lighting"
  }, {
    path: "/engine-oil",
    component: _d1e97080,
    name: "engine-oil"
  }, {
    path: "/exhaust-parts",
    component: _13ac460c,
    name: "exhaust-parts"
  }, {
    path: "/fuel-filters",
    component: _5f6d3f1d,
    name: "fuel-filters"
  }, {
    path: "/gap-insurance-guide",
    component: _700a33e2,
    name: "gap-insurance-guide"
  }, {
    path: "/historical-MOT-Check",
    component: _f507107a,
    name: "historical-MOT-Check"
  }, {
    path: "/how-to-cancel-a-mot",
    component: _d7b79b36,
    name: "how-to-cancel-a-mot"
  }, {
    path: "/InformationSecurityBreachReporting",
    component: _4d7ea36c,
    name: "InformationSecurityBreachReporting"
  }, {
    path: "/local-mot-guide",
    component: _894c53c8,
    name: "local-mot-guide"
  }, {
    path: "/lubricants-and-fluids",
    component: _67288d4a,
    name: "lubricants-and-fluids"
  }, {
    path: "/mot-center",
    component: _648d3429,
    name: "mot-center"
  }, {
    path: "/mot-center-cities",
    component: _3ea76883,
    name: "mot-center-cities"
  }, {
    path: "/mot-centers",
    component: _394a8880,
    name: "mot-centers"
  }, {
    path: "/mot-certificate",
    component: _eba4b6ce,
    name: "mot-certificate"
  }, {
    path: "/MOT-Check-News",
    component: _6c80086c,
    name: "MOT-Check-News"
  }, {
    path: "/MOT-history-check",
    component: _638f01b1,
    name: "MOT-history-check"
  }, {
    path: "/mot-near-me",
    component: _35d8a742,
    name: "mot-near-me"
  }, {
    path: "/mot-test-check",
    component: _0e4e0561,
    name: "mot-test-check"
  }, {
    path: "/motorcycle-battery",
    component: _ee16296c,
    name: "motorcycle-battery"
  }, {
    path: "/motorcycle-mot-check",
    component: _6189174a,
    name: "motorcycle-mot-check"
  }, {
    path: "/my-diesel-claim",
    component: _2a98fac7,
    name: "my-diesel-claim"
  }, {
    path: "/number-plates-in-the-uk",
    component: _177e442b,
    name: "number-plates-in-the-uk"
  }, {
    path: "/oil-filters",
    component: _3bec9c3d,
    name: "oil-filters"
  }, {
    path: "/pre-purchase-inspection",
    component: _5b10c35a,
    name: "pre-purchase-inspection"
  }, {
    path: "/prime-membership",
    component: _6b5b391d,
    name: "prime-membership"
  }, {
    path: "/print-mot-certificate",
    component: _30c1e3f9,
    name: "print-mot-certificate"
  }, {
    path: "/privacy",
    component: _6c29d3c5,
    name: "privacy"
  }, {
    path: "/rate-mot",
    component: _765432c4,
    name: "rate-mot"
  }, {
    path: "/refund-policy",
    component: _36b16d24,
    name: "refund-policy"
  }, {
    path: "/register-a-MOT-test-center",
    component: _24698f78,
    name: "register-a-MOT-test-center"
  }, {
    path: "/scrap-my-car",
    component: _37ea1c28,
    name: "scrap-my-car"
  }, {
    path: "/sell-car",
    component: _4de187d2,
    name: "sell-car"
  }, {
    path: "/sell-my-car",
    component: _91e0f26a,
    name: "sell-my-car"
  }, {
    path: "/sorn",
    component: _5cc4625e,
    name: "sorn"
  }, {
    path: "/sorn-my-car",
    component: _1e83f545,
    name: "sorn-my-car"
  }, {
    path: "/starter-motors",
    component: _4bc0a855,
    name: "starter-motors"
  }, {
    path: "/steering-fluid",
    component: _59fe15a7,
    name: "steering-fluid"
  }, {
    path: "/suspension-fluid",
    component: _432e7593,
    name: "suspension-fluid"
  }, {
    path: "/terms",
    component: _2465b0f8,
    name: "terms"
  }, {
    path: "/the-ultimate-guide-to-tyres",
    component: _516c6b92,
    name: "the-ultimate-guide-to-tyres"
  }, {
    path: "/trademark",
    component: _d988d2a4,
    name: "trademark"
  }, {
    path: "/transmission-oil",
    component: _b220453c,
    name: "transmission-oil"
  }, {
    path: "/tyre-insurance",
    component: _69f1cb84,
    name: "tyre-insurance"
  }, {
    path: "/ulez-check",
    component: _48285d80,
    name: "ulez-check"
  }, {
    path: "/ULEZ-claim",
    component: _1c87a7b4,
    name: "ULEZ-claim"
  }, {
    path: "/van-mot-check",
    component: _3a3fc540,
    name: "van-mot-check"
  }, {
    path: "/VDI-check",
    component: _4a61aa53,
    name: "VDI-check"
  }, {
    path: "/vehicle-battery-types",
    component: _c1abeed6,
    name: "vehicle-battery-types"
  }, {
    path: "/vehicle-insurance",
    component: _14f87414,
    name: "vehicle-insurance"
  }, {
    path: "/vehicle-insurance-in-the-uk",
    component: _5f16d583,
    name: "vehicle-insurance-in-the-uk"
  }, {
    path: "/vulnerable-consumer-policy-and-complaint-handling-procedure",
    component: _32f22909,
    name: "vulnerable-consumer-policy-and-complaint-handling-procedure"
  }, {
    path: "/we-buy-any-car",
    component: _bfdb35b4,
    name: "we-buy-any-car"
  }, {
    path: "/whatmot",
    component: _3ef432ea,
    name: "whatmot"
  }, {
    path: "/when-is-my-mot-due",
    component: _69bcad81,
    name: "when-is-my-mot-due"
  }, {
    path: "/wing-mirrors",
    component: _96a800ea,
    name: "wing-mirrors"
  }, {
    path: "/book-a-mot-in-northern-ireland/confirm-vehicle",
    component: _0d69a848,
    name: "book-a-mot-in-northern-ireland-confirm-vehicle"
  }, {
    path: "/book-mot/:slug?",
    component: _d2d320c2,
    name: "book-mot-slug"
  }, {
    path: "/free-mot-check/:reg?",
    component: _832bf05c,
    name: "free-mot-check-reg"
  }, {
    path: "/mot-center/:city",
    component: _5d762938,
    name: "mot-center-city"
  }, {
    path: "/mot-centers-in/:postcode?",
    component: _2451aa42,
    name: "mot-centers-in-postcode"
  }, {
    path: "/mot-centers/:city",
    component: _2c1d3b0a,
    name: "mot-centers-city"
  }, {
    path: "/MOT-Check-News/:slug?",
    component: _6ac96724,
    name: "MOT-Check-News-slug"
  }, {
    path: "/register-a-MOT-test-center/:token?",
    component: _22e01178,
    name: "register-a-MOT-test-center-token"
  }, {
    path: "/mot-center/:city?/check-mot",
    component: _b494bfbc,
    name: "mot-center-city-check-mot"
  }, {
    path: "/mot-center/:city?/check-mot/:station",
    component: _569c5218,
    name: "mot-center-city-check-mot-station"
  }, {
    path: "/mot-center/:city?/check-mot/:station/:id?",
    component: _38c7fa80,
    name: "mot-center-city-check-mot-station-id"
  }, {
    path: "/mot-center/:city?/:station",
    component: _3b009116,
    name: "mot-center-city-station"
  }, {
    path: "/mot-centers/:city?/:id",
    component: _7c4870ba,
    name: "mot-centers-city-id"
  }, {
    path: "/mot-center/:city?/:station/:id",
    component: _7e8be0fe,
    name: "mot-center-city-station-id"
  }, {
    path: "/",
    component: _3d6810e8,
    name: "index"
  }, {
    path: "/:sellcar",
    component: _7344e308,
    name: "sellcar"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
